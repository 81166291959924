import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Seo } from "../components/";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Support" keywords={[`support`, `donations`]} mdxType="Seo" />
    <h1 {...{
      "id": "support"
    }}>{`Support`}</h1>
    <p>{`People and organizations I regularly support.`}</p>
    <h2 {...{
      "id": "recurring-basis"
    }}>{`Recurring basis`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.marbridge.org/"
        }}>{`Marbridge`}</a>{`, a non-profit residential community in Austin, TX supporting adults with cognitive disabilities.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.arcaustin.org/"
        }}>{`The Arc of the Capital Area`}</a>{`, a nonprofit organization committed to empowering Central Texans with intellectual and developmental disabilities and their families through compassionate case management and innovative programs.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.patreon.com/diversifytech/posts"
        }}>{`Veni Kunche`}</a>{`, building Diversify Tech.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/users/tatianamac/sponsorship"
        }}>{`Tatiana Mac`}</a>{`, independent designer pushing forward rebuilding tech with inclusion, accessibility, web performance, and ethics at the core.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.open-austin.org/"
        }}>{`Open Austin`}</a>{`, volunteer citizen brigade advocating for open government, open data, and civic application development.`}</li>
    </ul>
    <h2 {...{
      "id": "ad-hoc-basis"
    }}>{`Ad Hoc basis`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mypossibilities.org/"
        }}>{`My Possibilities`}</a>{`, a non-profit organization serving adults with disabilities -- the first full-day, full-year educational program of its kind in Collin County, TX.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hotlabrescue.org/hotlab/index.jsp"
        }}>{`Heart of Texas Lab Rescue`}</a>{` in Austin, TX.`}</li>
    </ul>
    <h2 {...{
      "id": "journalism"
    }}>{`Journalism`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.texastribune.org/"
        }}>{`Austin Chronicle`}</a>{`, local news.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.kut.org/"
        }}>{`KUT Radio`}</a>{`, local NPR affiliate.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.texastribune.org/"
        }}>{`The Texas Tribune`}</a>{`, local/state news.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.washingtonpost.com/"
        }}>{`The Washington Post`}</a>{`, national news.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.theatlantic.com/"
        }}>{`The Atlantic`}</a>{`, national news.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.economist.com/"
        }}>{`The Economist`}</a>{`, world news.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      